<template>
    <el-scrollbar class="main-container">
        <div class="container">
            <div class="first-title">如何获取我的拼多多PID</div>
            <div class="line">
                1、首先注册多多进宝（拼多多联盟）<a href="http://jinbao.pinduoduo.com" target="_blank">http://jinbao.pinduoduo.com</a>，很简单，手机号和验证码就行了
            </div>
            <img :src="require('@/assets/img/pid/pdd/1.png')" alt="">
            <div class="line">2、在推广管理创建一个推广位，输入推广位名称就行了，然后得到拼多多ＰＩＤ</div>
            <img :src="require('@/assets/img/pid/pdd/2.png')" alt="">
            <div class="line">３、把得到的ＰＩＤ，输入到淘鹊桥拼多多ＰＩＤ设置</div>
            <img :src="require('@/assets/img/pid/pdd/3.png')" alt="">
            <div class="line">４、然后点击授权，授权后状态应该返回正常</div>
            <img :src="require('@/assets/img/pid/pdd/4.png')" alt="">
            <div class="line">５、然后拼多多就可以转链啦</div>
            <img :src="require('@/assets/img/pid/pdd/5.png')" alt="">
        </div>
    </el-scrollbar>
</template>

<script>
    export default {

    }
</script>

<style scoped lang="scss">
    * {
        font-size: 16px;
        color: #222;
    }
    .main-container {
        position: relative;
        height: calc(100vh - 100px);
        display: flex;
        flex-direction: column;
        background: #fff;
        border-radius: 10px 10px 0 0;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .container {
        padding: 40px 40px 100px;
        .first-title {
            font-size: 24px;
            margin-bottom: 50px;
        }
        .line {
            margin-top: 20px;
        }
        .second-title {
            margin-top: 40px;
            font-size: 18px;
        }
        .red {
            color: #FE1E00;
        }
    }
    a {
        color: #5A50EF;
    }
    img {
        display: block;
        margin: 20px 0 0 50px;
        max-width: calc(100% - 50px);
    }
</style>